import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import BoxelButton from '../button';
import BoxelSidebarCardContainer from '../sidebar/card-container';
import or from 'ember-truth-helpers/helpers/or';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const HelpBox = setComponentTemplate(precompileTemplate(`
  <BoxelSidebarCardContainer class="boxel-help-box" ...attributes>
    <header class="boxel-help-box__title">
      {{svgJar "help-circle" width="20px" height="20px" aria-hidden="true"}}
      {{or @prompt "Need help?"}}
    </header>
    {{#if (has-block)}}
      <div>
        {{yield}}
      </div>
    {{/if}}
    <BoxelButton
      @as="anchor"
      @href={{@url}}
      @kind="secondary-light"
      class="boxel-help-box__button"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{or @buttonText "Contact Support"}}
    </BoxelButton>
  </BoxelSidebarCardContainer>
`, {
  strictMode: true,
  scope: () => ({
    BoxelSidebarCardContainer,
    svgJar,
    or,
    BoxelButton
  })
}), templateOnly("index", "HelpBox"));
export default HelpBox;
var _class;

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import cn from '@cardstack/boxel/helpers/cn';
import eq from 'ember-truth-helpers/helpers/eq';
import gt from 'ember-truth-helpers/helpers/gt';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import { on } from '@ember/modifier';
import element from 'ember-element-helper/helpers/element';
import { fn, hash } from '@ember/helper';
import { action } from '@ember/object';
import '@cardstack/boxel/styles/global.css';
import './index.css';
let ProgressSteps = setComponentTemplate(precompileTemplate(`
    <ul class="boxel-progress-steps" ...attributes>
      {{#each @progressSteps as |progressStep i|}}
        {{#let (gt @completedCount i) (eq @completedCount i) as |isCompleted isCurrent|}}
          <li
            class={{cn "boxel-progress-steps__item" boxel-progress-steps__item--completed=isCompleted boxel-progress-steps__item--current=isCurrent}}
          >
            {{#let (element (if @onClickStep "a" "span")) as |Tag|}}
              {{! @glint-expect-error couldn't quite figure out how to type ember-element-helper properly }}
              <Tag class="boxel-progress-steps__item-grid" href="#" {{on "click" (fn this.clickStep i)}}>
                <span class="boxel-progress-steps__item-bullet">
                  {{#if isCompleted}}
                    {{svgJar "check-mark" width="10px" height="10px"}}
                  {{/if}}
                </span>
                {{#if (has-block)}}
                  {{yield (hash title=progressStep.title data=progressStep index=i completed=isCompleted current=isCurrent)}}
                {{else}}
                  <span>{{progressStep.title}}</span>
                {{/if}}
              </Tag>
            {{/let}}
          </li>
        {{/let}}
      {{/each}}
    </ul>
  `, {
  strictMode: true,
  scope: () => ({
    gt,
    eq,
    cn,
    element,
    on,
    fn,
    svgJar,
    hash
  })
}), (_class = class ProgressSteps extends Component {
  clickStep(i, ev) {
    ev.preventDefault();

    if (this.args.onClickStep) {
      this.args.onClickStep(i);
    }
  }

}, (_applyDecoratedDescriptor(_class.prototype, "clickStep", [action], Object.getOwnPropertyDescriptor(_class.prototype, "clickStep"), _class.prototype)), _class));
export { ProgressSteps as default };
var _class;

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import PowerCalendar from 'ember-power-calendar/components/power-calendar';
import { action } from '@ember/object';
import '@cardstack/boxel/styles/global.css';
import './index.css';
let BoxelCalendar = setComponentTemplate(precompileTemplate(`
    <PowerCalendar
      @selected={{@selected}}
      @onSelect={{this.handleSelect}}
      @center={{@center}}
      @onCenterChange={{this.handleCenterChange}}
      class="boxel-calendar"
      data-test-boxel-calendar
      ...attributes
      as |calendar|
    >
      <calendar.Nav />
      <calendar.Days @weekdayFormat="min" @minDate={{@minDate}} @maxDate={{@maxDate}} />
    </PowerCalendar>
  `, {
  strictMode: true,
  scope: () => ({
    PowerCalendar,
    calendar
  })
}), (_class = class BoxelCalendar extends Component {
  handleSelect(val) {
    this.args.onSelect?.(val.date);
  }

  handleCenterChange(val) {
    this.args.onCenterChange?.(val.date);
  }

}, (_applyDecoratedDescriptor(_class.prototype, "handleSelect", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCenterChange", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCenterChange"), _class.prototype)), _class));
export { BoxelCalendar as default };
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { helper } from '@ember/component/helper';
export class MenuItem {
  constructor(text, type, options) {
    _defineProperty(this, "text", void 0);

    _defineProperty(this, "type", void 0);

    _defineProperty(this, "dangerous", void 0);

    _defineProperty(this, "selected", void 0);

    _defineProperty(this, "disabled", void 0);

    _defineProperty(this, "header", void 0);

    _defineProperty(this, "icon", void 0);

    _defineProperty(this, "action", void 0);

    _defineProperty(this, "url", void 0);

    _defineProperty(this, "inactive", void 0);

    _defineProperty(this, "tabindex", void 0);

    _defineProperty(this, "id", void 0);

    this.text = text;
    this.type = type;
    this.action = options.action;
    this.id = options.id;
    this.dangerous = options.dangerous || false;
    this.selected = options.selected || false;
    this.disabled = options.disabled || false;
    this.header = options.header || false;
    this.icon = options.icon || undefined;
    this.inactive = options.inactive;
    this.tabindex = options.tabindex || 0;
  }

}
export function menuItemFunc(params, named) {
  let text = params[0];
  let opts = Object.assign({}, named);
  opts.action = params[1];
  return new MenuItem(text, 'action', opts);
}
export default helper(menuItemFunc);
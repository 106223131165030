var _class;

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import BoxelInputGroup from '../../input-group';
import SelectableTokenItem from '../selectable-token-item';
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import cn from '@cardstack/boxel/helpers/cn';
let SelectableTokenAmount = setComponentTemplate(precompileTemplate(`
    <BoxelInputGroup
      @id={{this.id}}
      @placeholder="0.00"
      @value={{@value}}
      @invalid={{unless @disabled @invalid}}
      @errorMessage={{@errorMessage}}
      @helperText={{@helperText}}
      @disabled={{@disabled}}
      @onInput={{@onInput}}
      @onBlur={{fn @onInput @value}}
      @autocomplete="off"
      @inputmode="decimal"
      class="boxel-input-selectable-token-amount"
      ...attributes
    >
      <:after as |Accessories|>
        <Accessories.Select
          class="boxel-input-selectable-token-amount__select"
          @placeholder="Choose token"
          @options={{@tokens}}
          @selected={{@token}}
          @searchField="symbol"
          @searchEnabled={{@searchEnabled}}
          @disabled={{@disabled}}
          @onChange={{@onChooseToken}}
          @onBlur={{this.onBlurToken}}
          @dropdownClass="boxel-input-selectable-token-amount__dropdown"
          @verticalPosition="below" as |item itemCssClass|
        >
          <SelectableTokenItem
            @item={{item}}
            class={{cn itemCssClass "boxel-input-selectable-token-amount__dropdown-item"}}
          /> 
        </Accessories.Select>
      </:after>
    </BoxelInputGroup>
  `, {
  strictMode: true,
  scope: () => ({
    BoxelInputGroup,
    fn,
    Accessories,
    SelectableTokenItem,
    cn
  })
}), (_class = class SelectableTokenAmount extends Component {
  get id() {
    return this.args.id || guidFor(this);
  }

  onBlurToken(_select, ev) {
    return this.args.onBlurToken?.(ev);
  }

}, (_applyDecoratedDescriptor(_class.prototype, "onBlurToken", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onBlurToken"), _class.prototype)), _class));
export { SelectableTokenAmount as default };
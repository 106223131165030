import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import './index.css';
export default class BoxelInputErrorMessage extends Component {}
setComponentTemplate(precompileTemplate(`
    <div class="boxel-input-error-message" aria-live="polite" data-test-boxel-input-error-message ...attributes>
      {{@message}}
    </div>
  `, {
  strictMode: true
}), BoxelInputErrorMessage);
var _class;

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import { action } from '@ember/object';
import Component from '@glimmer/component';
import BoxelSelect from '@cardstack/boxel/components/boxel/select';
import BoxelDropdownTrigger from '@cardstack/boxel/components/boxel/dropdown/trigger';
import cn from '@cardstack/boxel/helpers/cn';
import not from 'ember-truth-helpers/helpers/not';
import or from 'ember-truth-helpers/helpers/or';
import './index.css';
let BoxelInputRangedNumberPicker = setComponentTemplate(precompileTemplate(`
    <BoxelSelect
      class={{cn
        "boxel-ranged-number-picker"
        boxel-ranged-number-picker--selected=@value
      }}
      ...attributes
      @options={{this.rangedNumbers}}
      @selected={{@value}}
      @placeholder={{this.placeholder}}
      @disabled={{@disabled}}
      @onChange={{this.setSelectedNumber}}
      @renderInPlace={{true}}
      @dropdownClass="boxel-ranged-number-picker__dropdown"
      {{! @glint-expect-error: ember-power-select types triggerComponent as a string only }}
      @triggerComponent={{component BoxelDropdownTrigger
        icon=@icon
        label=(or @value this.placeholder)
        isMissingValue=(not @value)
      }}
      as |item itemCssClass|
    >
      <div class={{cn itemCssClass "boxel-ranged-number-picker__item"}}>
        {{item}}
      </div>
    </BoxelSelect>
  `, {
  strictMode: true,
  scope: () => ({
    BoxelSelect,
    cn,
    BoxelDropdownTrigger,
    or,
    not
  })
}), (_class = class BoxelInputRangedNumberPicker extends Component {
  setSelectedNumber(number) {
    this.args.onChange(parseInt(number));
  }

  get placeholder() {
    return this.args.placeholder || 'Pick Number';
  }

  get rangedNumbers() {
    const {
      min,
      max
    } = this.args;
    const length = max - min + 1;
    const items = Array.from({
      length
    }, (_, i) => {
      const currentNumber = min + i;
      return currentNumber.toString();
    });
    return items;
  }

}, (_applyDecoratedDescriptor(_class.prototype, "setSelectedNumber", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedNumber"), _class.prototype)), _class));
export { BoxelInputRangedNumberPicker as default };
import { helper } from '@ember/component/helper';
export function copyToClipboard( // eslint-disable-next-line @typescript-eslint/no-unused-vars
_params, {
  elementId,
  value,
  onCopy
}) {
  return function () {
    let sourceInput = document.querySelector(`#${elementId}`);

    if (sourceInput) {
      sourceInput.select();
      document.execCommand('copy');
      onCopy?.();
    } else if (value) {
      navigator.clipboard.writeText(value);
      onCopy?.();
    } else {
      console.warn(`copyToClipboard: element not found with id ${elementId}`);
    }
  };
}
export default helper(copyToClipboard);
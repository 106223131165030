var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { equal } from 'macro-decorators';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import BoxelButton from '../button';
import ActionStatusArea from './action-status-area';
import InfoArea from './info-area';
import cn from '@cardstack/boxel/helpers/cn';
import { concat, hash } from '@ember/helper';
import and from 'ember-truth-helpers/helpers/and';
let ActionChin = setComponentTemplate(precompileTemplate(`
    <div
      class={{cn
        "boxel-action-chin"
        (if @state (concat "boxel-action-chin--" @state))
        (if @disabled "boxel-action-chin--disabled")
        (if @stepNumber (concat "boxel-action-chin--has-step"))
        @class
      }}
      ...attributes
      data-test-boxel-action-chin
      data-test-boxel-action-chin-state={{@state}}
    >
      {{!-- Not accounting for 0 as a step number --}}
      {{#if @stepNumber}}
        <div class="boxel-action-chin__step" data-step={{@stepNumber}} data-test-boxel-action-chin-step={{@stepNumber}}>
          <span class="boxel-sr-only">Step {{@stepNumber}}</span>
        </div>
      {{/if}}
      {{#if (and (has-block "inProgress") this.isInProgress)}}
        {{yield
          (hash
            ActionButton=(component
              BoxelButton
              kind="primary"
              loading=true
              disabled=@disabled
              class="boxel-action-chin__action-button"
            )
            CancelButton=(component
              BoxelButton
              kind="secondary-dark"
              disabled=@disabled
              class="boxel-action-chin__cancel-button"
            )
            ActionStatusArea=ActionStatusArea
            InfoArea=(component
              InfoArea
              class="boxel-action-chin__info-area"
            )
          )
          to="inProgress"
        }}
      {{else if (and (has-block "memorialized") this.isMemorialized)}}
        {{!--
          if using ActionButton and ActionStatusArea together, the grid layout will put them at the exact same spot,
          causing a visual bug.
        --}}
        {{yield
          (hash
            ActionButton=(component
              BoxelButton
              kind="secondary-light"
              class="boxel-action-chin__memorialized-action-button"
              disabled=@disabled
            )
            ActionStatusArea=(component
              ActionStatusArea
              icon="success"
            )
            InfoArea=(component
              InfoArea
              class="boxel-action-chin__info-area"
            )
          )
          to="memorialized"
        }}
      {{else}}
        {{yield
          (hash
            ActionButton=(component
              BoxelButton
              kind="primary"
              disabled=@disabled
            )
            CancelButton=(component
              BoxelButton
              kind="secondary-dark"
              disabled=@disabled
              class="boxel-action-chin__cancel-button"
            )
            ActionStatusArea=ActionStatusArea
            InfoArea=(component
              InfoArea
              class="boxel-action-chin__info-area"
            )
          )
        }}
      {{/if}}
    </div>
  `, {
  strictMode: true,
  scope: () => ({
    cn,
    concat,
    and,
    hash,
    BoxelButton,
    ActionStatusArea,
    InfoArea
  })
}), (_dec = equal('args.state', 'default'), _dec2 = equal('args.state', 'in-progress'), _dec3 = equal('args.state', 'memorialized'), (_class = class ActionChin extends Component {
  constructor(...args) {
    super(...args);

    _initializerDefineProperty(this, "isDefault", _descriptor, this);

    _initializerDefineProperty(this, "isInProgress", _descriptor2, this);

    _initializerDefineProperty(this, "isMemorialized", _descriptor3, this);
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isDefault", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isInProgress", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isMemorialized", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class)));
export { ActionChin as default };
import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { hash } from '@ember/helper';
import cn from '@cardstack/boxel/helpers/cn';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const Item = setComponentTemplate(precompileTemplate(`
  <section class="boxel-control-panel__item">
    <heading
      class={{
        cn
        "boxel-control-panel__item-heading"
        boxel-control-panel__item-heading--is-active=@isActive
      }}>
      {{svgJar
        @icon
        width="18px"
        height="18px"
        class="boxel-control-panel__item-icon"
      }}
      {{@title}}
    </heading>
    <div class="boxel-control-panel__item-body">
      {{yield}}
    </div>
  </section>
`, {
  strictMode: true,
  scope: () => ({
    cn,
    svgJar
  })
}), templateOnly("index", "Item"));
export default class ControlPanel extends Component {}
setComponentTemplate(precompileTemplate(`
    <div class="boxel-control-panel">
      {{yield (hash Item=Item)}}
    </div>
  `, {
  strictMode: true,
  scope: () => ({
    hash,
    Item
  })
}), ControlPanel);
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import Container from './container';
import Item from './item';
import ErrorMessage from '../input/error-message';
import and from 'ember-truth-helpers/helpers/and';
import eq from 'ember-truth-helpers/helpers/eq';
import or from 'ember-truth-helpers/helpers/or';
import cn from '@cardstack/boxel/helpers/cn';
import { hash } from '@ember/helper';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class RadioInput extends Component {}
setComponentTemplate(precompileTemplate(`
    {{#let (and @invalid @errorMessage) as |shouldShowErrorMessage|}}
      <Container
        @groupDescription={{@groupDescription}}
        @disabled={{@disabled}}
        @spacing={{or @spacing 'default'}}
        @orientation={{or @orientation "horizontal"}}
        class={{cn
          "boxel-radio-input"
          boxel-radio-input--invalid=@invalid
        }}
        ...attributes
      >
        {{#each @items as |item i|}}
          {{yield (hash
            component=(component Item
              name=@name
              disabled=@disabled
              hideRadio=@hideRadio
              hideBorder=@hideBorder
              onBlur=@onBlur
              checked=(if @checkedId (eq @checkedId item.id))
            )
            data=item
            index=i)}}
        {{/each}}
      </Container>
      {{#if shouldShowErrorMessage}}
        <ErrorMessage
          @message={{@errorMessage}}
        />
      {{/if}}
    {{/let}}

  `, {
  strictMode: true,
  scope: () => ({
    and,
    Container,
    or,
    cn,
    hash,
    Item,
    eq,
    ErrorMessage
  })
}), RadioInput);
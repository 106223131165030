import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import { isPresent } from '@ember/utils';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class SelectableTokenAmount extends Component {
  get shouldUseSvgJar() {
    return isPresent(this.args.item.logoURI) && /^[a-zA-Z-_]+$/.test(this.args.item.logoURI);
  }

  get shouldRenderImage() {
    return isPresent(this.args.item.logoURI) && !this.shouldUseSvgJar;
  } // Makes glint happy


  get logoURI() {
    return this.args.item.logoURI || '';
  }

}
setComponentTemplate(precompileTemplate(`
    <div data-test-token={{@item.symbol}} ...attributes>
      {{#if this.shouldUseSvgJar}}
        {{svgJar
          this.logoURI
          class="boxel-selectable-token-icon__icon"
          role="presentation"
        }}
      {{/if}}
      {{#if this.shouldRenderImage}}
        <img src={{this.logoURI}}
          class="boxel-selectable-token-icon__icon"
          loading="lazy"
          role="presentation"
        />
      {{/if}}
      {{@item.symbol}} ({{@item.name}})
    </div>
  `, {
  strictMode: true,
  scope: () => ({
    svgJar
  })
}), SelectableTokenAmount);
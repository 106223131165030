import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import { TextAccessory, SelectAccessory, ButtonAccessory, IconButtonAccessory } from './accessories';
import { TextareaControl, InputControl } from './controls';
import ErrorMessage from '../input/error-message';
import and from 'ember-truth-helpers/helpers/and';
import cn from '@cardstack/boxel/helpers/cn';
import { concat, hash } from '@ember/helper';
import { guidFor } from '@ember/object/internals';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export default class InputGroup extends Component {
  get elementId() {
    return this.args.id || guidFor(this);
  }

  get inputGroupBlockArg() {
    return {
      elementId: this.elementId
    };
  }

}
setComponentTemplate(precompileTemplate(`
    {{#let
        (and @invalid @errorMessage)
        (hash
          Text=TextAccessory
          Button=(component ButtonAccessory kind="secondary-light")
          IconButton=IconButtonAccessory
          Select=SelectAccessory
        )
        (hash
          Input=InputControl
          Textarea=TextareaControl
        )
      as |shouldShowErrorMessage Accessories Controls|}}
      <div
        class={{cn
          "boxel-input-group"
          boxel-input-group--invalid=@invalid
          boxel-input-group--disabled=@disabled
        }}
        data-test-boxel-input-group
        ...attributes
      >
        {{yield Accessories this.inputGroupBlockArg to="before"}}
        {{#if (has-block "default")}}
          {{yield Controls Accessories this.inputGroupBlockArg }}
        {{else}}
          <InputControl
            id={{this.elementId}}
            @placeholder={{@placeholder}}
            @disabled={{@disabled}}
            @readonly={{@readonly}}
            @required={{@required}}
            @value={{@value}}
            @onInput={{@onInput}}
            @onBlur={{@onBlur}}
            autocomplete={{@autocomplete}}
            inputmode={{@inputmode}}
            aria-describedby={{if @helperText (concat "helper-text-" this.elementId) false}}
            aria-invalid={{if @invalid "true"}}
            aria-errormessage={{if shouldShowErrorMessage (concat "error-message-" this.elementId) false}}
          />
        {{/if}}
        {{yield Accessories this.inputGroupBlockArg to="after"}}
      </div>
      {{#if shouldShowErrorMessage}}
          <ErrorMessage
            id={{concat "error-message-" this.elementId}}
            class="boxel-input-group__error-message"
            data-test-boxel-input-group-error-message
            @message={{@errorMessage}}
          />
      {{/if}}
      {{#if @helperText}}
        <div id={{concat "helper-text-" this.elementId}} class="boxel-input-group__helper-text" data-test-boxel-input-group-helper-text>{{@helperText}}</div>
      {{/if}}
    {{/let}}
  `, {
  strictMode: true,
  scope: () => ({
    and,
    hash,
    TextAccessory,
    ButtonAccessory,
    IconButtonAccessory,
    SelectAccessory,
    InputControl,
    TextareaControl,
    cn,
    concat,
    ErrorMessage
  })
}), InputGroup);
;
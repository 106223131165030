function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import ErrorMessage from './error-message';
import cn from '@cardstack/boxel/helpers/cn';
import and from 'ember-truth-helpers/helpers/and';
import not from 'ember-truth-helpers/helpers/not';
import { concat } from '@ember/helper';
import element from 'ember-element-helper/helpers/element';
import { on } from '@ember/modifier';
import optional from 'ember-composable-helpers/helpers/optional';
import pick from 'ember-composable-helpers/helpers/pick';
import { guidFor } from '@ember/object/internals';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import './token-amount/index.css';
import './validation-state/index.css';
export default class BoxelInput extends Component {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "helperId", guidFor(this));
  }

  get id() {
    return this.args.id || this.helperId;
  }

}
setComponentTemplate(precompileTemplate(`
    {{#if (and (not @required) @optional)}}
      <div class="boxel-input__optional">Optional</div>
    {{/if}}
    {{#let (and @invalid @errorMessage) as |shouldShowErrorMessage|}}
      {{#let (element (if @multiline "textarea" "input")) as |InputTag|}}
        {{!-- @glint-expect-error stumped on how to type this --}}
        <InputTag
          class={{cn "boxel-input" boxel-input--invalid=@invalid}}
          id={{this.id}}
          value={{@value}}
          required={{@required}}
          disabled={{@disabled}}
          aria-describedby={{if @helperText (concat "helper-text-" this.helperId) false}}
          aria-invalid={{if @invalid "true"}}
          aria-errormessage={{if shouldShowErrorMessage (concat "error-message-" this.helperId) false}}
          data-test-boxel-input
          data-test-boxel-input-id={{@id}}
          {{on "input" (pick "target.value" (optional @onInput))}}
          {{on "blur" (optional @onBlur)}}
          ...attributes
        />
        {{#if shouldShowErrorMessage}}
          <ErrorMessage
            id={{concat "error-message-" this.helperId}}
            class="boxel-input__error-message"
            @message={{@errorMessage}}
          />
        {{/if}}
        {{#if @helperText}}
          <div id={{concat "helper-text-" this.helperId}} class="boxel-input__helper-text" data-test-boxel-input-helper-text>{{@helperText}}</div>
        {{/if}}
      {{/let}}
    {{/let}}
  `, {
  strictMode: true,
  scope: () => ({
    and,
    not,
    element,
    cn,
    concat,
    on,
    pick,
    optional,
    ErrorMessage
  })
}), BoxelInput);
import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import '@cardstack/boxel/styles/global.css';
import './index.css';
import BoxelSidebarSection from './section';
const SidebarContainer = setComponentTemplate(precompileTemplate(`
  <div class="boxel-sidebar" ...attributes>
    {{yield BoxelSidebarSection}}
  </div>
`, {
  strictMode: true,
  scope: () => ({
    BoxelSidebarSection
  })
}), templateOnly("index", "SidebarContainer"));
export default SidebarContainer;
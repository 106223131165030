function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import '@cardstack/boxel/styles/global.css';
import './index.css';
import cn from '@cardstack/boxel/helpers/cn';
import { array, concat, hash } from '@ember/helper';
import or from 'ember-truth-helpers/helpers/or';
import eq from 'ember-truth-helpers/helpers/eq';
import not from 'ember-truth-helpers/helpers/not';
import LoadingIndicator from '../loading-indicator';
import { LinkTo } from '@ember/routing';
export default class ButtonComponent extends Component {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "defaultSize", 'base');

    _defineProperty(this, "defaultKind", 'secondary-light');
  }

}
setComponentTemplate(precompileTemplate(`
    {{#let (cn
      "boxel-button"
      @class
      (concat "boxel-button--size-" (or @size this.defaultSize))
      (concat "boxel-button--kind-" (or @kind this.defaultKind))
      (if @tooltip "boxel-button--with-tooltip")
    ) as |classes|}}
      {{#if (or (not @as) (eq @as "button"))}}
        <button
          class={{cn classes (if @loading "boxel-button--loading")}}
          tabindex={{if @loading -1 0}}
          disabled={{@disabled}}
          data-hover={{@tooltip}}
          data-test-boxel-button
          ...attributes
        >
        {{#if @loading}}
          <LoadingIndicator class="boxel-button__loading-indicator" @color="var(--boxel-button-text-color)" data-test-boxel-button-loading-indicator />
        {{/if}}
          {{yield}}
        </button>
      {{else if (eq @as "anchor")}}
        <a
          class={{classes}}
          href={{unless @disabled @href}}
          data-test-boxel-button 
          data-hover={{@tooltip}}
          ...attributes
        >
          {{yield}}
        </a>
      {{else if (eq @as "link-to")}}
        <LinkTo
          class={{classes}}
          @route={{@route}}
          @models={{if @models @models (array)}}
          @query={{or @query (hash)}}
          @disabled={{@disabled}}
          data-test-boxel-button 
          tabindex={{if @disabled -1 0}}
          data-hover={{@tooltip}}
          ...attributes
        >
          {{yield}}
        </LinkTo>
      {{/if}}
    {{/let}}
  `, {
  strictMode: true,
  scope: () => ({
    cn,
    concat,
    or,
    not,
    eq,
    LoadingIndicator,
    LinkTo,
    array,
    hash
  })
}), ButtonComponent);
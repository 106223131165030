import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import BoxelCardContainer from '@cardstack/boxel/components/boxel/card-container';
import BoxelHeader from '../header';
import BoxelActionChin from '../action-chin';
import cn from '@cardstack/boxel/helpers/cn';
import ActionContainerSection from './section';
import '@cardstack/boxel/styles/global.css';
import './index.css';
const ActionContainer = setComponentTemplate(precompileTemplate(`
  <BoxelCardContainer
    class={{cn
      "boxel-action-container"
    }}
    data-test-boxel-action-container
    ...attributes
  >
    {{#if @header}}
      <BoxelHeader
        class="boxel-action-container__header"
        @header={{@header}}
        data-test-boxel-action-header
      />
    {{/if}}

    {{yield ActionContainerSection (component BoxelActionChin class='boxel-action-container__footer')}}
          
  </BoxelCardContainer>
`, {
  strictMode: true,
  scope: () => ({
    BoxelCardContainer,
    cn,
    BoxelHeader,
    ActionContainerSection,
    BoxelActionChin
  })
}), templateOnly("index", "ActionContainer"));
export default ActionContainer;
import templateOnly from "@ember/component/template-only";
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import PowerSelect, { BeforeOptions } from 'ember-power-select/components/power-select';
import cn from '@cardstack/boxel/helpers/cn';
import './index.css';
const BoxelSelect = setComponentTemplate(precompileTemplate(`
  <PowerSelect
    class={{cn "boxel-select" boxel-select--selected=@selected}}
    @options={{@options}}
    @searchField={{@searchField}}
    @selected={{@selected}}
    @selectedItemComponent={{@selectedItemComponent}}
    @placeholder={{@placeholder}}
    @onChange={{@onChange}}
    @onBlur={{@onBlur}}
    @renderInPlace={{@renderInPlace}}
    @verticalPosition={{@verticalPosition}}
    @dropdownClass={{cn "boxel-select__dropdown" @dropdownClass}}
    @triggerComponent={{@triggerComponent}}
    @disabled={{@disabled}}
    @matchTriggerWidth={{false}}
    @eventType="click"
    @searchEnabled={{@searchEnabled}}
    @beforeOptionsComponent={{component BeforeOptions autofocus=false}}
    ...attributes
    as |item|
  >
    {{yield item "boxel-select__item"}}
  </PowerSelect>
`, {
  strictMode: true,
  scope: () => ({
    PowerSelect,
    cn,
    BeforeOptions
  })
}), templateOnly("index", "BoxelSelect"));
export default BoxelSelect;
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import BoxelDropdown from '../../dropdown';
import BoxelCalendar, { Day } from '../../calendar';
import BoxelDropdownTrigger from '../../dropdown/trigger';
import registerElement from '@cardstack/boxel/modifiers/register-element';
import set from 'ember-set-helper/helpers/set';
import { fn } from '@ember/helper';
import '@cardstack/boxel/styles/global.css';
import './index.css';
export { Day };
export default class BoxelInputDate extends Component {
  get dateString() {
    return this.args.value?.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    });
  }

  constructor(owner, args) {
    super(owner, args);

    _defineProperty(this, "center", void 0);

    this.center = this.args.value || new Date();
  }

}
setComponentTemplate(precompileTemplate(`
    <BoxelDropdown
      class="boxel-input-date"
      data-test-boxel-input-date
    >
      <:trigger as |bindings|>
        <BoxelDropdownTrigger
          @icon="calendar"
          @label={{this.dateString}}
          @disabled={{@disabled}}
          {{bindings}}
          {{registerElement (set this 'triggerElement')}}
          class="boxel-input-date__trigger"
          data-test-boxel-input-date-trigger
          ...attributes
        />
      </:trigger>
      <:content>
        <div
          class="boxel-input-date__calendar-container"
          {{registerElement (set this 'containerElement')}}
        >
          <BoxelCalendar
            @selected={{@value}}
            @onSelect={{@onChange}}
            @center={{this.center}}
            @onCenterChange={{fn (mut this.center)}}
            @minDate={{@minDate}}
            @maxDate={{@maxDate}}
          />
        </div>
      </:content>
    </BoxelDropdown>
  `, {
  strictMode: true,
  scope: () => ({
    BoxelDropdown,
    BoxelDropdownTrigger,
    registerElement,
    set,
    BoxelCalendar,
    fn
  })
}), BoxelInputDate);
var _class;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import BoxelSelect from '../../select';
import ErrorMessage from '../error-message';
import SelectableTokenItem from '../selectable-token-item';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import and from 'ember-truth-helpers/helpers/and';
import cn from '@cardstack/boxel/helpers/cn';
import { concat } from '@ember/helper';
import '@cardstack/boxel/styles/global.css';
import './index.css';
let TokenSelect = setComponentTemplate(precompileTemplate(`
    {{#let (and @invalid @errorMessage) as |shouldShowErrorMessage|}}
      <BoxelSelect
        @placeholder={{this.placeholder}}
        @options={{@tokens}}
        @selected={{@value}}
        @searchEnabled={{@searchEnabled}}
        @searchField="symbol"
        @disabled={{@disabled}}
        @onChange={{@onChooseToken}}
        @onBlur={{this.onBlur}}
        @verticalPosition="below"
        aria-invalid={{if @invalid "true"}}
        aria-errormessage={{if shouldShowErrorMessage (concat "error-message-" this.helperId) false}}
        class={{cn
          "boxel-input-token-select"
          boxel-input-token-select--disabled=@disabled
          boxel-input-token-select--invalid=@invalid
        }}
        ...attributes
      as |item itemCssClass|>
        <SelectableTokenItem
          @item={{item}}
          class={{cn itemCssClass "boxel-input-token-select__dropdown-item"}}
        /> 
      </BoxelSelect>
        {{#if shouldShowErrorMessage}}
          <ErrorMessage
            id={{concat "error-message-" this.helperId}}
            @message={{@errorMessage}}
          />
        {{/if}}
      {{/let}}
  `, {
  strictMode: true,
  scope: () => ({
    and,
    BoxelSelect,
    concat,
    cn,
    SelectableTokenItem,
    ErrorMessage
  })
}), (_class = class TokenSelect extends Component {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "helperId", guidFor(this));
  }

  get placeholder() {
    return this.args.placeholder || 'Choose a token';
  }

  onBlur(_select, ev) {
    this.args.onBlur?.(ev);
  }

}, (_applyDecoratedDescriptor(_class.prototype, "onBlur", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onBlur"), _class.prototype)), _class));
export { TokenSelect as default };
import { setComponentTemplate } from "@ember/component";
import { precompileTemplate } from "@ember/template-compilation";
import Component from '@glimmer/component';
import BoxelButton from '../../button';
import cn from '@cardstack/boxel/helpers/cn';
import { svgJar } from '@cardstack/boxel/utils/svg-jar';
import './index.css';
export default class BoxelDropdownTrigger extends Component {}
setComponentTemplate(precompileTemplate(`
    <BoxelButton
      class={{cn "boxel-dropdown-trigger" boxel-dropdown-trigger--showing-placeholder=@isMissingValue}}
      @disabled={{@disabled}}
      ...attributes
    >
      {{#if @icon}}
        {{svgJar @icon class="boxel-dropdown-trigger__icon" role="presentation"}}
      {{/if}}
      {{@label}}
      {{svgJar "caret-down" class="boxel-dropdown-trigger__caret" width=8 height=8 role="presentation"}}
    </BoxelButton>
  `, {
  strictMode: true,
  scope: () => ({
    BoxelButton,
    cn,
    svgJar
  })
}), BoxelDropdownTrigger);